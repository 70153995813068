
import axios from 'axios';
export default {
    async createFileAttachments(params)  {
        return await axios.post(`file_attachments/create` , params)
    },
    async createFileAttachmentsList(params)  {
        return await axios.post(`file_attachments/create/list` , params)
    },
    async updateFileAttachmentsColumn(column , value , data)  {
        return await axios.put(`file_attachments/update_list?${column}=${value}` , data)
    },
    async deleteFileAttachmentsList(list)  {
        return await axios.delete(`file_attachments/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportFileAttachments(column , value)  {
        return await axios.get(`file_attachments/report?${column}=${value}`)
    },
    async getAllFileAttachments()  {
        return await axios.get(`file_attachments/all`)
    },
    async getOneFileAttachments(file_attachment_id)  {
        return await axios.get(`file_attachments/all/${file_attachment_id}`)
    },
    async getFileAttachmentsByColumn(column , value)  {
        return await axios.get(`file_attachments/filter?column=${column}&value=${value}`)
    },
    async deleteFileAttachments(file_attachment_id)  {
        return await axios.delete(`file_attachments/delete/${file_attachment_id}`)
    },
    async updateFileAttachments(file_attachment_id , params)  {
        return await axios.put(`file_attachments/update/${file_attachment_id}` , params)
    },
    
    async upload_files(files, store_id)  {
        console.log(files);
        return await axios.post(`file_attachments/upload/${store_id}` , files, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(r =>{
            return r
        })
        .catch(e =>{
            console.log(e);
            return e
        })
    }
}
        