
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <v-layout row wrap px-4 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.file_attachments.list_path">
                            <template v-slot:item="{item}">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                    {{ item.title }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <form autocomplete="off">
                    <v-layout row wrap>
                        <v-flex xs12>
                            <v-card class="mx-1" outlined @click="openFile">
                                <v-card-text class="pa-5 text-center">
                                    <input type="file" multiple style="display:none" ref="file" @change="uploadFiles">
                                    <h2 class="my-10"> {{ $store.getters.language.data.file_attachments.tap_to_select_file_upload }} </h2>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </form>
                <v-layout row wrap mt-5>
                    <v-flex xs12>
                        <v-card class="mx-1" outlined>
                            <v-card-text>
                                <v-layout row wrap>
                                    <v-flex xs6 md4 sm3 lg2 xl2 v-for="(item, index) in rows" :key="index">
                                        <v-card flat class="ma-1">
                                            <v-card-text>
                                                <v-badge @click.native="selectFileAttachments(item)" overlap  color="error" icon="mdi-delete">
                                                    <v-img :src="$image_url + 'file_attachments/serve_file/' + item.file_attachment_path" aspect-ratio="2" contain width="100px" height="100px"></v-img>
                                                    {{item.file_attachment_name}}
                                                </v-badge>
                                            </v-card-text>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </v-card-text>
                            <v-card-actions v-if="selected_rows.length > 0">
                                <v-btn color="error" @click="delete_file_attachments_list">
                                    {{$store.getters.language.data.file_attachments.delete_list_btn}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.file_attachments.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.file_attachments.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteFileAttachments(selected_file_attachments.file_attachment_id)">
                {{$store.getters.language.data.file_attachments.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/file_attachments.request.js'
    export default {
        data() {
            return {
                file_attachments: {},
                search: '',
                loading : false,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                
                selected_file_attachments : {},
                delete_dialog: false,
                headers: [
                    { 
                        text: this.$store.getters.language.data.file_attachments.store_id,
                        align: 'start',
                        sortable: true,
                        value: 'store_id',
                    },
                    { 
                        text: this.$store.getters.language.data.file_attachments.file_attachment_path,
                        align: 'start',
                        sortable: true,
                        value: 'file_attachment_path',
                    },
                    { 
                        text: this.$store.getters.language.data.file_attachments.file_attachment_name,
                        align: 'start',
                        sortable: true,
                        value: 'file_attachment_name',
                    },
                    { 
                        text: this.$store.getters.language.data.file_attachments.file_attachment_upload_date,
                        align: 'start',
                        sortable: true,
                        value: 'file_attachment_upload_date',
                    },   
                    {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'file_attachment_id',
                    }
                ],
            }
        },
        computed: {
            rows() {
                return this.$store.getters.file_attachments_list
            }
        },
        mounted(){
            
        },
        methods: {
            openFile(){
                this.$refs.file.click();
            },
            uploadFiles(e){
                const files = e.target.files;
                if(files.length > 0){
                    // upload multiple files
                    var formData = new FormData();
                    for(var i = 0; i < files.length; i++){
                        formData.append('files', files[i]);
                    }

                    requests.upload_files(formData, this.$store.getters.store.store_id).then(response => {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.add_success,
                            color: 'success'
                        }
                        requests.getFileAttachmentsByColumn('store_id', this.$store.getters.store.store_id).then(r => {
                            this.$store.commit('setFileAttachmentsList', r.data.rows)
                        })
                    })
                    .catch(error => {
                        this.snackbar = {
                            value: true,
                            text: error.message,
                            color: 'error'
                        }
                    })
                }else{
                    this.snackbar = {
                        value: true,
                        text: 'No file selected',
                        color: 'error'
                    }
                }
            },
            addFileAttachments() {
                this.loading_btn = true
                requests.createFileAttachments(this.file_attachments).then(r => {
                    if (r.status == 200) {
                        this.file_attachments = {}
                        this.$store.state.file_attachments_list.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.add_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteFileAttachments(file_attachment_id) {
                requests.deleteFileAttachments(file_attachment_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.$store.state.file_attachments_list = this.$store.state.file_attachments_list.filter(f =>{
                            return f.file_attachment_id != file_attachment_id
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            deleteFileAttachmentsList() {
                let ids = this.selected_rows.map(m => m.file_attachment_id)
                requests.deleteFileAttachmentsList(ids).then(r => {
                    if (r.status == 200) {
                        this.$store.state.file_attachments_list = this.$store.state.file_attachments_list.filter(f =>{  
                            return ids.indexOf(f.file_attachment_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            
            selectFileAttachments(i) {
                this.selected_file_attachments = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    